import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
export default new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/pc_index'
        }, {
            name: '登录',
            path: '/login',
            component: () => import ('../views/login.vue')
        }, {
            name: '首页',
            path: '/pc_index',
            component: () => import ('../views/pc_index.vue')
        }, {
            name: '首页',
            path: '/m_index',
            component: () => import ('../views/m_index.vue')
        },


    ]
})
