import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import dataV from '@jiaminghi/data-view'

import ElementUI from 'element-ui';
import Cookies from "js-cookie";
import 'element-ui/lib/theme-chalk/index.css';
import "../public/scss/phone.scss"
Vue.use(dataV);
Vue.prototype.$cookie = Cookies;
Vue.use(ElementUI);
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
